import React from "react";

import n1 from "../../../assets/n1.png";
import n2 from "../../../assets/n2.png";
import n3 from "../../../assets/n3.png";
import { Link } from "react-router-dom";
const LettersWork = (props) => {

    const box = [
        {
            images:n1,
            title:"Sign Up to My Letters from Ireland",
            description:"Ireland is one click away, starting from $14.99 per month."
        },  {
            images:n2,
            title:"Receive Mail from Ireland",
            description:"Mail ships every month, and delivery starts on the 10th of the month."
        },  {
            images:n3,
            title:"Enjoy the Stories of Ireland",
            description:"Experience Ireland through the adventures told from our Founder!"
        }
    ]
    return (
        <div className="letters-container letters-works" ref={props.sendToView}>

            <h1>How it Works?</h1>

            <div className="ltworks-display">
                {box.map((item) => {
                    return(
                        <div className="ltworks-content">
                        <img src={item.images} alt="#"/>
                        <h2>{item.title}</h2>
                        <p>{item.description}</p>
                    </div>
                    )
                })}
       
            </div>

            <p className="button1 letters-btn1"  onClick={props.find3}>Join Now</p>
        </div>
    )
}

export default LettersWork;