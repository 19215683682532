import React, { useState } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
// import ReactHtmlParser from "react-html-parser";
function Faqs({ posts, homepage }) {
  const [toggle, setToggle] = useState(-1);

  const faq1 = [
    {
      question: "EXISTING SUBSCRIPTIONS",
      answer:
        "How can I log in to my account to manage it? - Skip a month(s), change billing or shipping details, cancel plan etc.",
    },
    {
      question: "For members who DO NOT pay via PayPal:",
      answer:
        "When you feel like changing billing details or shipping address or indeed need to skip a month or even cancel your subscription, you can now manage your own plan by logging into your account using your designated password. If this is the first time logging into your account and you DO NOT HAVE YOUR PASSWORD TO HAND the carry out the following steps: 1) Click on the Account icon on the top right of the homepage. 2) Enter your email address and click 'Forgot Password' and you can reset your password in a matter of seconds. You can update your credit card information and amend the shipping address as above.",
    },
    {
      question: "For members who pay via PayPal:",
      answer:
        "Please log in to your own PayPal account to manage your plan with us!",
    },
    {
      question:
        "Oops! ~ I paused my plan and now I would like to unpause. How do I do this?",
      answer:
        "If you paused your subscription by mistake and now want to unpause it, you won't be able to un-pause - HOWEVER - we at MyIrelandBox can unpause it for you! Please email help@myirelandbox.com and one of the team will unpause for you!",
    },
  ];
  const faq2 = [
    {
      question: "How do I Subscribe to a recurring subscription? ",
      answer:
        "Choosing a MyIrelandBox is easy! Click on the 'subscribe now' button You will be brought to the 'subscribe now' page showing plan types. Click on the desired plan that suits you. Then go to checkout! MyIrelandBox is available to the USA, Canada, EU, UK, and Australia.",
    },
    {
      question:
        "How do I gift a non-recurring subscription - i.e a one-time gift?",
      answer:
        "~Click on the 'subscribe now' button You will be brought to the 'subscribe now' page showing plan types. Click on the desired planthat suits you. Then go to checkout! ~On the left-hand side, third line down, you can select 'this is a gift'. ~On the right-hand side you will see 'when will my subscription start' and please complete that - select today's date so that next month will ship. ~Then you will see in the next dropbox down 'How many shipments do you want to send?' and you can select one cycle or more or 'until I cancel' !",
    },
  ];

  const faq3 = [
    {
      question:
        "I have changed my mind and wish to buy a different subscription than what I initially added to my cart. How do I do this? ",
      answer:
        "Just go back out to the homepage and to 'subscribe' and choose a different plan - that will replace what was in your cart.",
    },
  ];

  const faq4 = [
    {
      question: "How much does it cost?",
      answer:
        "A monthly MyIrelandBox costs $49.50/month plus shipping of $15.50 when shipped to the USA, Canada, and Australia. Shipping within Ireland, UK, and Europe are $15.50. If you go for a 3/6 or 12-month recurring plan you can lock in additional savings and some great special offers.",
    },
    {
      question: "When is my card charged?",
      answer:
        "Monthly subscriptions will be charged when you first purchase a subscription and then, auto-renews every month unless cancelled.Three-month subscriptions, you are committing to paying three monthly charges for three shipments. The subscription then renews automatically unless cancelled or paused.Six-month subscriptions, you are committing to paying six monthly charges for six shipments. The subscription then renews automatically unless cancelled or paused. Twelve-month subscriptions, you are committing to paying twelve monthly charges for twelve shipments. The subscription then renews automatically unless cancelled or paused. For prepaid Gift Subscriptions, your credit card will be charged the full amount at the time of purchase.",
    },
    {
      question: "Is my credit card information secure?",
      answer:
        "Most definitely. MyIrelandBox is partnering with a trusted subscription platform (STRIPE) to manage all of our subscriptions. Our site is PCI Compliant and is rigorously tested for security.",
    },
  ];

  const faq5 = [
    {
      question:
        "Where do you ship to and what are the costs for international shipping from Ireland?",
      answer:
        "MyIrelandBox is available to the USA, Canada, EU, and the UK by express international post. Costs are currently $15.50USD (as of August 1st, 2020 for new subscriptions) to ship a MyIrelandBox. When is my MyIrelandBox shipped? Order your box by the last day of each month in order to receive your box for the following month. Your first box will ship within the first ten days of the month after you subscribe. For example, if you order your MyIrelandBox on October 10th (or prior to the last day of the month), your first box will be sent on the 10th of November.",
    },
    {
      question: "Do I get to choose what is in my MyIrelandBox?",
      answer:
        "Each month has an Irish theme. The content of each box is a surprise! Your MyIrelandBox will be filled with a selection of Irish-made crafts, homeware, beauty products, or jewelry. Each monthly box will be filled with a surprise of totally different products. It’s your way of receiving a treat from Ireland every month!",
    },
  ];

  const faq6 = [
    {
      question: "How do I gift a MyIrelandBox?",
      answer:
        "At check out, simply select Gift. Include a message for the recipient which will be neatly included in the MyIrelandBox!",
    },
  ];

  const faq7 = [
    {
      question: "What if a product is broken or damaged?",
      answer:
        "We are so sorry if an item is broken or damaged. We scrutinize each box to ensure that nothing is damaged and all is perfect and wrapped up tight for the recipient before being shipped. Unfortunately, however, we can not supervise the delivery and treatment of your package by the post handler. If you do receive a broken or damaged item, please contact us as soon as possible and we will work to get you a replacement as quickly as we can. Please note that with the handmade products included in the MyIrelandBox, as they are made by hand very slight variations in appearance are normal and do not necessarily render the product defective or broken.",
    },
    {
      question: "What if I do not like one of the products received?",
      answer:
        "We work hard using our taste and curating skills to find the best of the best in Ireland for you. If for some reason, however, you don’t love the product as much as we do, then we would suggest that you could gift the product to a friend.",
    },

    {
      question: "Can I send my MyIrelandBoxes to different people?",
      answer:
        "Yes, you can -And it’s a great idea for Birthdays and other special occasions. Just email us the details over at help@myirelandbox.com and tell us when and where and to who you would like your MyIrelandBox to be shipped.",
    },
    {
      question: "FOR BRANDS",
      answer:
        "I am an Irish producer or artist – how can I be featured in future MyIrelandBox shipments? Please email us at info@myirelandbox.com",
    },
  ];
  return (
    <div className="faqs">
      <Navbar posts={homepage} />
      <div className="faqs-div">
        <h1>Frequently asked questions</h1>

        {faq1.map((item, i) => {
          return (
            <>
              <div className="faq-color">
                <div
                  className="faq-icon"
                  onClick={() => (toggle === i ? setToggle(-1) : setToggle(i))}
                >
                  <h2>{item.question}</h2>
                  {toggle === i ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </div>
                {toggle === i && (
                  <p
                    className={
                      toggle === i ? "transition" : "transition-fade-out"
                    }
                  >
                    <p className="answer">{item.answer}</p>
                  </p>
                )}
              </div>
            </>
          );
        })}

        <h1>SIGNING UP</h1>

        {faq2.map((item, i) => {
          return (
            <>
              <div className="faq-color">
                <div
                  className="faq-icon"
                  onClick={() =>
                    toggle === i + 4 ? setToggle(-1) : setToggle(i + 4)
                  }
                >
                  <h2>{item.question}</h2>
                  {toggle === i + 4 ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </div>
                {toggle === i + 4 && (
                  <p
                    className={
                      toggle === i + 4 ? "transition" : "transition-fade-out"
                    }
                  >
                    <p className="answer">{item.answer}</p>
                  </p>
                )}
              </div>
            </>
          );
        })}

        <h1>Deleting items from my cart:</h1>

        {faq3.map((item, i) => {
          return (
            <>
              <div className="faq-color">
                <div
                  className="faq-icon"
                  onClick={() =>
                    toggle === i + 6 ? setToggle(-1) : setToggle(i + 6)
                  }
                >
                  <h2>{item.question}</h2>
                  {toggle === i + 6 ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </div>
                {toggle === i + 6 && (
                  <p
                    className={
                      toggle === i + 6 ? "transition" : "transition-fade-out"
                    }
                  >
                    <p className="answer">{item.answer}</p>
                  </p>
                )}
              </div>
            </>
          );
        })}

        <h1>SUBSCRIPTIONS</h1>

        {faq4.map((item, i) => {
          return (
            <>
              <div className="faq-color">
                <div
                  className="faq-icon"
                  onClick={() =>
                    toggle === i + 7 ? setToggle(-1) : setToggle(i + 7)
                  }
                >
                  <h2>{item.question}</h2>
                  {toggle === i + 7 ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </div>
                {toggle === i + 7 && (
                  <p
                    className={
                      toggle === i + 7 ? "transition" : "transition-fade-out"
                    }
                  >
                    <p className="answer">{item.answer}</p>
                  </p>
                )}
              </div>
            </>
          );
        })}

        <h1>SHIPPING</h1>

        {faq5.map((item, i) => {
          return (
            <>
              <div className="faq-color">
                <div
                  className="faq-icon"
                  onClick={() =>
                    toggle === i + 10 ? setToggle(-1) : setToggle(i + 10)
                  }
                >
                  <h2>{item.question}</h2>
                  {toggle === i + 10 ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </div>
                {toggle === i + 10 && (
                  <p
                    className={
                      toggle === i + 10 ? "transition" : "transition-fade-out"
                    }
                  >
                    <p className="answer">{item.answer}</p>
                  </p>
                )}
              </div>
            </>
          );
        })}

        <h1>SENDING A GIFT</h1>

        {faq6.map((item, i) => {
          return (
            <>
              <div className="faq-color">
                <div
                  className="faq-icon"
                  onClick={() =>
                    toggle === i + 12 ? setToggle(-1) : setToggle(i + 12)
                  }
                >
                  <h2>{item.question}</h2>
                  {toggle === i + 12 ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </div>
                {toggle === i + 12 && (
                  <p
                    className={
                      toggle === i + 12 ? "transition" : "transition-fade-out"
                    }
                  >
                    <p className="answer">{item.answer}</p>
                  </p>
                )}
              </div>
            </>
          );
        })}

        <h1>RETURNS</h1>

        {faq7.map((item, i) => {
          return (
            <>
              <div className="faq-color">
                <div
                  className="faq-icon"
                  onClick={() =>
                    toggle === i + 13 ? setToggle(-1) : setToggle(i + 13)
                  }
                >
                  <h2>{item.question}</h2>
                  {toggle === i + 13 ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </div>
                {toggle === i + 13 && (
                  <p
                    className={
                      toggle === i + 13 ? "transition" : "transition-fade-out"
                    }
                  >
                    <p className="answer">{item.answer}</p>
                  </p>
                )}
              </div>
            </>
          );
        })}
      </div>

      <Footer />
    </div>
  );
}

export default Faqs;
