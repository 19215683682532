import React from "react";
import Footer from "../Footer";
import ReactHtmlParser from "react-html-parser";
import Navbar from "../Navbar";
import Loader from "../utilities/Loader";

function PrivacyPolicy({ posts, homepage }) {
  return posts ? (
    <div className="privacy-policy">
      <Navbar posts={homepage} />
      <div className="privacy-div">
        {ReactHtmlParser(posts.content.rendered)}
      </div>
      <Footer />
    </div>
  ) : (
    <Loader />
  );
}

export default PrivacyPolicy;
