import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import LazyLoad from 'react-lazyload';

import "./blog.css";
import Navbar from "../../../Navbar";
import Footer from "../../../Footer";
import rmicon from "../../../../assets/rmicon.svg";
import { param } from "jquery";

export function Singleblog({ posts }) {
  const params = useParams();
  // console.log(params);
  // console.log(posts);
  const [blog, setBlog] = useState({
    posts: [],
    isLoaded: false,
  });
  const [otherData, setOtherData] = useState({
    otherPost: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
    .get(`https://cms.myirelandbox.com/wp-json/wp/v2/blog?slug=${params.slug}`)
      .then((res) => {
        setBlog({
          blog: res.data[0],
          isLoaded: true,
        });

        axios
          .get(
            `https://cms.myirelandbox.com/wp-json/wp/v2/blog?per_page=3&exclude=${res.data[0].id}`
          )
          .then((res) => {
            setOtherData({
              otherPost: res.data,
              isLoaded: true,
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [params.slug]);
  // console.log(blog.blog.slug)

  if (blog.isLoaded === true) {
    return (
      <>
        <Navbar posts={posts} />

        <div className="single-blog-page">
        <h1 className="singleblog-header">{blog.blog.acf.title}</h1>
        <LazyLoad height={200}>
          <img src={blog.blog.acf.featuredimg.url} className="singleblog-banner"/>
          </LazyLoad>
            <div className="singleblog-display">
            <div className="single-blog-content">
            {ReactHtmlParser(blog.blog.content.rendered)}{" "}
          </div>


          <div className="similarblogs">
          <h2>Similar Blogs</h2>

          <div className="singleblog-blogs">
            {otherData.otherPost

              .map((res) => {
                // console.log(res.id)
                return (
                  <div key={res} className="single-blog" id={res.id}>
                    <div className="blog-featured-img">
                      <img
                        className="postImg"
                        src={res.acf.featuredimg.url}
                        alt=""
                      />
                    </div>
                    <div className="blog-desc">
                      <Link to={"/singleblog/" + res.slug}>
                        <p className="blog-category">{res.acf.category}</p>
                        <h1>{res.acf.title}</h1>

                        {/* <p
                          className="blog-shortdesc"
                          dangerouslySetInnerHTML={{
                            __html: res.acf.content.substring(0, 100) + "...",
                          }}
                        ></p> */}

                        {/* <p className="read-more">
                          Read More
                          <span className="rmicon">
                            <img src={rmicon} alt="" />
                          </span>
                        </p> */}
                      </Link>
                    </div>

                  </div>
                );
              })}
          </div>
        </div>
            </div>
        </div>
       
        <div className="allblogs">
          <Link to="/blog">
            <button>All Blogs</button>
          </Link>
        </div>
        <Footer />
      </>
    );
  } else {
    return <p></p>;
  }
}
