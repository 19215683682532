import React, { useState } from "react";
import Product from "./Product";
import UserName from "./UserName";
import "../Styles/form.css";
import Logo from "../../../assets/logo.svg";
import Connections from "./Connections";
import Cities from "./Cities";
import EmailAddress from "./EmailAddress";
import { VscArrowSmallRight } from "react-icons/vsc";
import { Link } from "react-router-dom";
import Step1 from "../../../assets/Form/1 - Active.svg";
import Step2 from "../../../assets/Form/2 - Active.svg";
import Step3 from "../../../assets/Form/3 - Active.svg";
import Step4 from "../../../assets/Form/4 - Active.svg";
import Step5 from "../../../assets/Form/5 - Active.svg";
import { BiChevronLeft } from "react-icons/bi";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import TrustBoxSlider from "./TrustBoxSlider";
import Unlockedoffer from "./Unlockedoffer";
import LazyLoad from 'react-lazyload';


function Form({ posts, plans }) {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    product: [],
    city: "",
    email: localStorage.getItem("email"),
    accepted: false,
    connections: {},
    mailError: true,
    nameValid: true,
    lastNameValid: true,
    shop: "myirlandbox",
  });
//   const data = {
//     babyName: "",
//     birthMonth: "",
//     birthYear: "",
//     email: "",
//     momStyle: "",
//     gift: "",
//  checked:"Yes-marketable",
//     plan:"",
// shop: "momsandbabes",
//     };
  ReactGA.initialize("UA-39344816-2");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const fetchData = async () => {
    try {
      const response = await fetch('https://myirelandbox.blackbird.marketing/api/klaviyoHandler', {
        method: 'POST', // Set the request method to POST
        headers: {
          'Content-Type': 'application/json', // Set the content type for JSON data
        },
        body: JSON.stringify(formData), // Replace with your actual data
      });

      const result = await response.json();
      // setData(result);
      console.log(result)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const FormTitle = [
    "Who is this for?",
    "What is your connection to Ireland?",
    "Where in Ireland would you most like to visit?",
    "What is your email?",
    `Pick a plan for ${formData.name} :`,
  ];
  const FormParagraph = [
    "Help us understand who will be receiving their MyIrelandBox!",
  ];
console.log("formdata",formData)
  const PageDisplay = () => {
    switch (page) {
      case 0:
        return <UserName formData={formData} setFormData={setFormData} posts={posts} />;
      case 1:
        return (
          <Connections
            formData={formData}
            setFormData={setFormData}
            page={page}
            setPage={setPage}
            step2={posts}
          />
        );
      case 2:
        return <Cities formData={formData} setFormData={setFormData} />;
      case 3:
        return <EmailAddress formData={formData} setFormData={setFormData} />;
      default:
        return (
          <Product
            formData={formData}
            setFormData={setFormData}
            page={page}
            setPage={setPage}
            step5={posts}
            plans={plans}
          />
        );
    }
  };


  const agreement = () => <p>Agree</p>;

  const handleClick = () => {
    window.open(
      `https://checkout.myirelandbox.com/checkout/buy/${formData.product.id}`,
      "_self"
    );
  };

  function gtag_report_conversion(url) {
    const callback = () => {
      if (typeof url !== "undefined") {
        window.location = url;
      }
    };

    window.gtag("event", "Submit lead form", {
      send_to: "AW-796702121/XbcsCIPF8MsDEKnr8vsC",
      event_callback: callback,
    });
    return false;
  }

  const disabledSteps = (formData) =>
    formData.name === "" ||
    formData.lastName === "" ||
    formData.nameValid === false ||
    formData.lastNameValid === false;

  const disabledStep5 = (formData) =>
    formData.accepted === false ||
    formData.mailError === false ||
    formData.email === "" ||
    formData.name === "" ||
    formData.lastName === "";

  const isLastPage = page === FormTitle.length - 1;
  const isFormValid =
    formData.name !== "" &&
    formData.lastName !== "" &&
    formData.nameValid &&
    formData.lastNameValid;
  const isAddToCartDisabled = Object.keys(formData.product).length === 0;
  const isGoBackDisabled = page === 0;
  const shouldDisplayGoBack = page !== 1 && page !== 3 && page !== 4;
  const shouldDisplayAddToCart = page === 4;

  const getStyles = () => {
    switch (page) {
      case 2:
        return { justifyContent: "center" };
      case 3:
      case 4:
        return { display: "none" };
      case 1:
        return {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        };
      default:
        return { display: "flex" };
    }
  };

  const getChevronStyles = () => {
    switch (page) {
      case 2:
        return { display: "none" };
      case 0:
        return { display: "none" };
      default:
        return { display: "block" };
    }
  };

  const getGoBackTextStyles = () => {
    return page === 1 ? { display: "block" } : { display: "none" };
  };

  const getSkipButtonStyles = () => {
    return page === 0 ? { display: "none" } : { display: "flex" };
  };

  const goBack = () => {
    setPage(page - 1);
  };

  const skipStep = () => {
    setPage((currPage) => currPage + 1);
  };

  return (
    <div className="form">
      <Unlockedoffer posts={posts} />

      <div className="mobile-header-form">
    
        <div className="logoFormContainer">
          <Link
            to={
              window.location.href.indexOf("cw") !== -1
                ? "/cw"
                : "/"
            }
          >
               <LazyLoad height={200}>
            <img src={Logo} className="logoForm" alt="logoForm" />
            </LazyLoad>
          </Link>
        </div>
        {/* <FreeButlers /> */}
      </div>

      <div className="form-body">
     
        <div className="progressbar">
          {[Step1, Step2, Step3, Step4].map((Step, index) => (
            <React.Fragment key={Step}>
              <button
                disabled={index > 0 && disabledSteps(formData)}
                onClick={() => setPage(index)}
              >
                <img
                  src={Step}
                  alt={`step${index + 1}`}
                  style={{ opacity: page === index ? "1" : "0.5" }}
                />
              </button>
              {index < 4 && <div className="progress-line"></div>}
            </React.Fragment>
          ))}
          <button disabled={disabledStep5(formData)} onClick={() => setPage(4)}>
            <img
              src={Step5}
              alt="step5"
              style={{ opacity: page === 4 ? "1" : "0.5" }}
            />
          </button>
        </div>

        <div className="form-container"></div>

        <div className="headerForm">
          <h1>{FormTitle[page]}</h1>
        </div>
        <div className="paragraphForm">
          <p>{FormParagraph}</p>
        </div>

        <form
          action="https://cms.myirelandbox.com/wp-content/submit.php"
          method="POST"
        >
          <div className="body">
            {PageDisplay()}
            <input
              name="email"
              value={formData.email}
              style={{ display: "none" }}
            />
            <input
              name="fname"
              value={formData.name}
              style={{ display: "none" }}
            />
            <input
              name="lname"
              value={formData.lastName}
              style={{ display: "none" }}
            />
            <input
              name="attraction"
              value={formData.city}
              style={{ display: "none" }}
            />
            <input
              name="connection"
              value={formData.connections.Explain}
              style={{ display: "none" }}
            />
            <iframe
            title="iframe"
              id="test2"
              name="dummyframe"
              style={{ display: "none", width: "0", height: "0" }}
            ></iframe>
          </div>
          <div
            className="submit-button-div"
            style={
              page === 3
                ? { backgroundColor: "#fffcf8" }
                : { backgroundColor: "transparent", display: "none" }
            }
          >
            {page > 0 && (
              <div
                className="goback-div"
                onClick={() => {
                  setPage(page - 1);
                }}
              >
                <BiChevronLeft size={20} className="submit-goback-icon" />
                <p
                  className="goback"
                  onClick={() => {
                    setPage(page - 1);
                  }}
                >
                  Go back
                </p>
              </div>
            )}
            {page === 3 && (
              <>
                <div
                  className="goBack"
                  onClick={() => {
                    setPage(page - 1);
                  }}
                >
                  <BiChevronLeft size={30} style={{ paddingTop: "0px" }} />
                </div>
                <button
                  type="submit"
                  disabled={
                    formData.mailError === false || formData.email === ""
                  }
                  style={{
                    display: "flex",
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                  }}
                  formTarget="dummyframe"
                  className="submitBtn"
                  id="subscribeButton"
                  onClick={() => {
                    if (formData.accepted === false) {
                      agreement();
                    } else {
                      ReactPixel.track("Lead");
                      setPage((currPage) => currPage + 1);
                      fetchData()
                    }
                    ReactGA.event({
                      category: "Signup Flow Lead",
                      action: "Lead",
                      label: "Sign-up",
                    });
                    gtag_report_conversion();
                  }}
                >
                  <p>Continue </p>
                  <VscArrowSmallRight size={25} className="rightArrow" />
                </button>
              </>
            )}
          </div>
        </form>

        <div
          className="formBtns"
          style={{ display: shouldDisplayGoBack ? "flex" : "none" }}
        >
          <div
            className="goback-div"
            style={{ display: isGoBackDisabled ? "none" : "none" }}
            onClick={() => setPage(page - 1)}
          >
            <BiChevronLeft
              size={20}
              style={{ display: isGoBackDisabled ? "none" : "none" }}
            />
            <p
              className="goback2"
              style={{ display: isGoBackDisabled ? "none" : "none" }}
              onClick={() => setPage(page - 1)}
            >
              Go back
            </p>
          </div>
          <div
            className="goBack"
            onClick={() => setPage(page - 1)}
            style={{ display: isGoBackDisabled ? "none" : "flex" }}
          >
            <BiChevronLeft
              size={30}
              style={{ paddingTop: "0px" }}
              id="goback-svg"
            />
          </div>
          <button
            style={{
              display: "flex",
              borderTopLeftRadius:
                page === 1 || page === 3 || page === 4 ? "7px" : "0",
              borderBottomLeftRadius:
                page === 1 || page === 3 || page === 4 ? "7px" : "0",
              borderRadius: page === 0 ? "0" : "",
            }}
            disabled={isLastPage || !isFormValid}
            id={page === 0 ? "step_one_name" : page === 1 ? "step_two_connection" : page === 2 ? "step_three_attraction" : page === 3 ? "step_four_email" :page === 4 ? "step_five_plan" : ""}
            onClick={() => setPage(page + 1)}
          >
            Continue <VscArrowSmallRight size={25} className="rightArrow" />
          </button>
          <button
            className="subblyBtn"
            style={{
              display: shouldDisplayAddToCart ? "flex" : "none",
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
            }}
            disabled={isAddToCartDisabled}
            id="addToCartButton"
            onClick={() => {
              ReactPixel.track("AddToCart");
              localStorage.removeItem("email");
              handleClick();
            }}
          >
            <p>Continue</p>{" "}
            <VscArrowSmallRight size={25} className="rightArrow" />
          </button>
        </div>

        <div className="skipBtnDiv" style={getStyles()}>
          <div className="goback-div" onClick={goBack}>
            <BiChevronLeft size={20} style={getChevronStyles()} />
            <p
              className="goback3"
              style={getGoBackTextStyles()}
              onClick={goBack}
            >
              Go back
            </p>
          </div>

          <button
            className="skipBtn"
            style={getSkipButtonStyles()}
            onClick={skipStep}
          >
            or, skip this step
          </button>
        </div>
      </div>

      <TrustBoxSlider />
    </div>
  );
}

export default Form;
