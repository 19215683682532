import React from "react";
//images
// import one from "../../../../assets/one.png";
import two from "../../../../assets/two.png";


function MemoryLane({ posts }) {
  return (
    <div class="timeline">
      
      <div class="container right">
        <div class="date">2012</div>
        <i class="icon fa fa-running"></i>
        <div class="content">
          {/* <img src={five} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
            Katharine dreams up the idea of a box of Irish made treasures delivered to the doors of the large community of people worldwide who have a connection and affinity with Ireland and all things Irish.
          </p>
        </div>
      </div>
      <div class="container left">
        <div class="date">2013</div>
        <i class="icon fa fa-running"></i>
        <div class="content">
          {/* <img src={five} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
            MyIrelandBox features in the Irish national newspapers.
          </p>
        </div>
      </div>
     
      <div class="container right">
        <div class="date">2013</div>
        <i class="icon fa fa-home"></i>
        <div class="content">
          <img src={two} alt="bannner" />
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
            Katharine and Thomas officially launch Ireland’s first ever subscription box, <a href="https://www.MyIrelandBox.com" to="route" target="_blank" style={{color: "#547f5f", fontWeight: "600", textDecoration: "none" }}>www.MyIrelandBox.com</a> and the first curated boxes of Irish made treasures are shipped in March.
          </p>
        </div>
      </div>
      <div class="container left">
        <div class="date">2015</div>
        <i class="icon fa fa-running"></i>
        <div class="content">
          {/* <img src={five} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
            MyIrelandBox goes from strength to strength creating a global community sharing stories of their connection with Ireland and their MyIrelandBox unboxing experiences.
          </p>
        </div>
      </div>
      <div class="container right">
        <div class="date">2017</div>
        <i class="icon fa fa-running"></i>
        <div class="content">
          {/* <img src={five} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
            MyIrelandBox brand grows as it features in Irish Country Magazine, The Irish Times and The Sunday Times.
          </p>
        </div>
      </div>

      <div class="container left">
        <div class="date">2019</div>
        <i class="icon fa fa-cog"></i>
        <div class="content">
          {/* <img src={six} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
          MyIrelandBox goes on tour to the USA, showcasing at The Big E, Springfield, Massachusetts, USA! Meeting many of our loyal US subscriber base.
          </p>
        </div>
      </div>
    
      <div class="container right">
        <div class="date">2020</div>
        <i class="icon fa fa-cog"></i>
        <div class="content">
          {/* <img src={eight} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
            MyIrelandBox launches a new revamped website.
          </p>
        </div>
      </div>
      <div class="container left">
        <div class="date">2020</div>
        <i class="icon fa fa-cog"></i>
        <div class="content">
          {/* <img src={eight} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
            MyIrelandBox launches <a href="https://www.MyIrelandBoxStore.com" to="route" target="_blank" style={{color: "#547f5f", fontWeight: "600", textDecoration: "none" }}>www.MyIrelandBoxStore.com</a>, a one stop shop for all the most popular items shipped in past MyIrelandBoxes.
          </p>
        </div>
      </div>
      <div class="container right">
        <div class="date">2020</div>
        <i class="icon fa fa-cog"></i>
        <div class="content">
          {/* <img src={eight} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
           The MyIrelandBox good news story makes the headlines and is featured on Irelands main TV channels, RTE’s main evening News slot The Six & Nine o Clock main evening news bulletins.

          </p>
        </div>
      </div>
      <div class="container left">
        <div class="date">2021</div>
        <i class="icon fa fa-cog"></i>
        <div class="content">
          {/* <img src={eight} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
          MyIrelandBox gives a lifeline to the small makers/business community of Ireland during the COVID pandemic. As MyIrelandBox subscribership soars, it places significant monthly orders to small Irish independent makers in a time of complete lockdown
          </p>
        </div>
      </div>
      <div class="container right">
        <div class="date">2021</div>
        <i class="icon fa fa-cog"></i>
        <div class="content">
          {/* <img src={eight} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
          MyIrelandBox outgrows its existing premises and moves to a bigger fulfilment center.
          </p>
        </div>
      </div>
      <div class="container left">
        <div class="date">2022</div>
        <i class="icon fa fa-cog"></i>
        <div class="content">
          {/* <img src={eight} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
            MyIrelandBox offers new subscription plans to suit its growing subscriber base.
          </p>
        </div>
      </div>
      <div class="container right">
        <div class="date">2022</div>
        <i class="icon fa fa-cog"></i>
        <div class="content">
          {/* <img src={eight} alt="bannner" /> */}
          <h2>MEMORY LANE</h2>
          <p className="memory-paragraph">
            MyIreland hires further staff, creating jobs and gears up for a busy Q4 2022 and even bigger 2023!
          </p>
        </div>
      </div>
    </div>
  );
}

export default MemoryLane;
