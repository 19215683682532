import React from "react";
import ReactHtmlParser from "react-html-parser";

function OurFounder({ posts, ourFounder }) {
  return (
    <div className="our-founder">
      <img
        src={ourFounder.acf.our_founder.our_founder.main_image}
        className="our-founder-image-1"
      />
      {ReactHtmlParser(ourFounder.acf.our_founder.our_founder.our_founder_text)}

      <div className="second-images-our-founder">
        <img
          src={ourFounder.acf.our_founder.our_founder.left_image}
          className="our-founder-image-2"
        />
        <img
          src={ourFounder.acf.our_founder.our_founder.right_image}
          className="our-founder-image-2"
        />
      </div>

      {ReactHtmlParser(
        ourFounder.acf.our_founder.our_founder.our_founder_text_2
      )}
    </div>
  );
}

export default OurFounder;
