import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import InsideProducts from "../Shared/Pages/PeekInside/InsideProducts";
import ReadytoJoin from "../Shared/Pages/PeekInside/ReadytoJoin";
import Loader from "../utilities/Loader";
import LazyLoad from 'react-lazyload';
function PeekInside({ inside, posts }) {
  if (inside === false && posts === false) return <Loader />;
  else {
    return (
      <div>
        <Navbar posts={posts} />
        <LazyLoad height={200}>
        <InsideProducts inside={inside} posts={posts} />
        </LazyLoad>
        <LazyLoad height={200}>
        <ReadytoJoin />
        </LazyLoad>
        <Footer />
      </div>
    );
  }
}

export default PeekInside;
