import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./secondBlog.css"
import LazyLoad from 'react-lazyload';


const Travel = () =>{
    const [blog, setBlog] = useState({
        posts: [],
        isLoaded: false,
      });
      const [featured, setFeatured] = useState({
        posts: [],
        isLoaded: false,
      });
    
      useEffect(() => {
        axios.get("https://cms.myirelandbox.com/wp-json/wp/v2/blog").then((res) => {
          setBlog({
            ...blog,
            posts: res.data,
            isLoaded: true,
          });
        });
        axios
          .get(
            "https://cms.myirelandbox.com/wp-json/wp/v2/blog?category_slug=featured"
          )
          .then((res) => {
            setFeatured({
              ...featured,
              posts: res.data,
              isLoaded: true,
            });
          })
          .catch((err) => console.log(err));
      }, []);

    return (
    <>
        <div className="articles">
           { blog.posts.filter((e) => e.acf.category === "Travel").slice(-1).map((item) =>{
              return (
                <Link to={"/singleblog/" + item.slug} className="last-blog">
                     <img
                       
                        src={item.acf.featuredimg.url}
                        alt=""
                      />
                     <div className="category">
                     
                         <p className="category-tag">{item.acf.category.toUpperCase()}</p>
                          <h2>{item.acf.title}</h2>
                          <p
                        className="blog-shortdesc"
                        dangerouslySetInnerHTML={{
                          __html: item.acf.content.substring(0, 100) + "..."
                        }}
                      ></p>

                     </div>
                </Link>
      
                  
              )
            

           })}
               <div className="relevant-display">
        { blog.posts.filter((e) => e.acf.category === "Travel").slice(-3, -1).map((item) =>{ 
            return (
             
                <Link to={"/singleblog/" + item.slug} className="relevant-articles">
                   <LazyLoad height={200}>
               <img src={item.acf.featuredimg.url} />
               </LazyLoad>
               <div className="relevant-description">
                 <p  className="category-tag">{item.acf.category.toUpperCase()}</p>
                 <h2>{item.acf.title}</h2>
           
               </div>
                 </Link>
            )
            })}
              </div>



           
        </div>
            <div className="grid-all">
            { blog.posts.filter((e) => e.acf.category === "Travel").slice(0, -3).map((item) =>{
            return (
              <Link to={"/singleblog/" + item.slug} className="all-articles">
                   <img
                     
                      src={item.acf.featuredimg.url}
                      alt=""
                    />
                   <div className="category">
                   
                       <p className="category-tag">{item.acf.category.toUpperCase()}</p>
                        <h2>{item.acf.title}</h2>
                    

                   </div>
              </Link>
    
                
            )
          

         })}
            </div>
    </>
    )
}
export default Travel;