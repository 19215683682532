import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import ReactHtmlParser from "react-html-parser";

function TermsOfServices({ posts, homepage }) {
  return (
    <div className="terms-of-services">
      <Navbar posts={homepage} />
      <div className="terms-div">{ReactHtmlParser(posts.content.rendered)}</div>
      <Footer />
    </div>
  );
}

export default TermsOfServices;
