import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import Zoom from "react-reveal/Zoom"; 

function Cities({ formData, setFormData }) {
  const options = [
    "The Cliffs of Moher",
    "Dingle Peninsula ",
    "The Ring of Kerry",
    "The Giants Causeway",
    "Dublin city",
    "County Wicklow",
    "The Gap of Dunloe",
    "The Rock of Cashel",
    "Famous Irish castles",
    "The Aran Islands",
    "Everywhere",
  ];
  const defaultOption = options[0];
  return (
    <Zoom delay={100} duration={200}>
      <div className="citiesDiv">
        <p>Attractions in Ireland</p>
        <Dropdown
          arrowClosed={
            <span className="arrow-closed">
              <RiArrowDropDownLine size="25px" />
            </span>
          }
          arrowOpen={
            <span className="arrow-open">
              <RiArrowDropUpLine size="25px" />
            </span>
          }
          options={options}
          onChange={(event) => setFormData({ ...formData, city: event.value })}
          // value={defaultOption}

          placeholder="Select an attraction"
          className="citiesDropwdown"
        />
        <input
          name="attraction"
          value={formData.city}
          style={{ display: "none" }}
        />
      </div>
    </Zoom>
  );
}

export default Cities;
