import React from "react";
const TrustBoxSlider = () => {
 
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      class="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="54ad5defc6454f065c28af8b"
      data-businessunit-id="602d09e970ea210001dc91ea"
      data-style-height="240px"
      data-style-width="100%"
      data-theme="light"
      data-stars="5"
      data-review-languages="en"
      ref={ref}
      id="trustBox-slider"
    >
      <a
        href="https://uk.trustpilot.com/review/myirelandbox.com"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBoxSlider;
