import React from "react";
import "./Styles/off.css";

function Off({ posts }) {
  return (
    <div id="offBody">
      <p className="offPragraph">
        FREE Butlers chocolate + 900million-year-old Connemara marble worry
        stone
      </p>
    </div>
  );
}

export default Off;
