import React from "react";
import "../../Styles/Homepage/seventhbanner.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SeventhBanner({ posts }) {
  const settings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <span></span>,
    prevArrow: <span></span>,
  };
  return (
    <>
      <div className="seventhBanner" id="seventhBanner-desktop">
        <div className="seventhBanner">
          <h2>{posts.acf.seventh_section.featured_heading}</h2>
          <div className="featuredImgs">
            <div className="upperimages">
              <img
                src={
                  posts.acf.seventh_section.featured_image[0].featured_image.url
                }
                alt="featured-img"
              />
              <img
                src={
                  posts.acf.seventh_section.featured_image[1].featured_image.url
                }
                alt="featured-img"
              />
              <img
                src={
                  posts.acf.seventh_section.featured_image[2].featured_image.url
                }
                alt="featured-img"
              />
            </div>
            <div className="underimages">
              <img
                src={
                  posts.acf.seventh_section.featured_image[3].featured_image.url
                }
                alt="featured-img"
              />
              <img
                src={
                  posts.acf.seventh_section.featured_image[4].featured_image.url
                }
                alt="featured-img"
              />
              <img
                src={
                  posts.acf.seventh_section.featured_image[5].featured_image.url
                }
                alt="featured-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="seventhBanner" id="seventhBanner-mobile">
        <h2>{posts.acf.seventh_section.featured_heading}</h2>

        <div >
          <Slider {...settings}>
            <img
              src={
                posts.acf.seventh_section.featured_image[0].featured_image.url
              }
              alt="featuredimg"
              className="slick-slide"
            />
            <img
              src={
                posts.acf.seventh_section.featured_image[1].featured_image.url
              }
              alt="featuredimg"
              className="slick-slide"
            />
            <img
              src={
                posts.acf.seventh_section.featured_image[2].featured_image.url
              }
              alt="featuredimg"
              className="slick-slide"
            />
            <img
              src={
                posts.acf.seventh_section.featured_image[3].featured_image.url
              }
              className="slick-slide"
              alt="featuredimg"
            />
            <img
              src={
                posts.acf.seventh_section.featured_image[4].featured_image.url
              }
              alt="featuredimg"
              className="slick-slide"
            />
            <img
              src={
                posts.acf.seventh_section.featured_image[5].featured_image.url
              }
              alt="featuredimg"
              className="slick-slide"
            />
          </Slider>
        </div>
      </div>
    </>
  );
}

export default SeventhBanner;
