import React, { useEffect, useState } from "react";

function CountDown({ posts, style }) {
  const calculateTimeLeft = () => {
    const now = new Date();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1); // 1st day of next month
    const difference = endOfMonth - now;
    
    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      
      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      setDays(timeLeft.days);
      setHours(timeLeft.hours);
      setMinutes(timeLeft.minutes);
      setSeconds(timeLeft.seconds);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  var countdownallow = posts.acf.first_section.countdownallow;
  // const display = () => {
  //   if (countdownallow === !true) {
  //     return "flex";
  //   } else {
  //     return "flex";
  //   }
  // };
  console.log(days);

  return (
       <>
    
{days <= 8 || countdownallow ?
    <div
      className={style + " ensureChristmas-navbar "}
      style={{ display: "flex" }}
    >
      <p className="ensure">{posts.acf.first_section.countdown_paragraph}</p>
      <div className="counter">
        <div className="days">
          <h1>{days}</h1>

          <p>DAYS</p>
        </div>
        <div className="dots">:</div>
        <div className="hours">
          <h1>{hours}</h1>

          <p>HOURS</p>
        </div>
        <div className="dots">:</div>
        <div className="minutes">
          <h1>{minutes}</h1>

          <p>MINUTES</p>
        </div>
        <div className="dots">:</div>
        <div className="sec">
          <h1>{seconds}</h1>
          <p>SECONDS</p>
        </div>
      </div>
    </div>
: "" }
       </>
  );
}

export default CountDown;
