import React from "react";
import { Widget } from "@typeform/embed-react";
import LazyLoad from 'react-lazyload';
function HeartInIreland() {
  return (
    <>
        <LazyLoad height={200}>
      <Widget
        id="VbEBow2V"
        style={{ width: "100%", height: "100%", position: "absolute" }}
        className="my-form"
        
      />
      </LazyLoad>
    </>
  );
}

export default HeartInIreland;
