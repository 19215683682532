import React from "react";

//CSS
import "./meetFounder.css";

//IMG

function MeetFounder({ plans }) {
  return (
    <div className="founder">
      <div
        className="founder-image fonder-data"
        style={{
          backgroundImage: `url(${plans.acf.planfirst.ourfounder.founderimg})`,
        }}
      ></div>
      <div className="founder-text fonder-data">
        <h1>{plans.acf.planfirst.ourfounder.founderheading}</h1>

        <h2>{plans.acf.planfirst.ourfounder.founderparagraph}</h2>
        <p>{plans.acf.planfirst.ourfounder.founderparagraph2}</p>
      </div>
    </div>
  );
}

export default MeetFounder;
