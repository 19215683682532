import React from "react";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import image from "../../../assets/404.png";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';


const P404 = ({ posts }) => {
  return (
    <>
      <Navbar posts={posts} />
      <div className="page404">
      <LazyLoad height={200}>
        <img src={image} alt="page404" />
        </LazyLoad>
        <p>This page does not exist.</p>
        <Link to="/">Go Back</Link>
      </div>
      <Footer />
    </>
  );
};

export default P404;
