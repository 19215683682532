import React, { useState, useEffect } from 'react';
import styles from './Stories.module.css';
import { Link } from 'react-router-dom';

const AcceptedMessage = ({ formData, id }) => {
  const [likes, setLikes] = useState(0);
  const [comment, setComment] = useState('');
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    // Load likes from localStorage if available
    const storedLikes = localStorage.getItem(`likes-${id}`);
    if (storedLikes !== null) {
      setLikes(parseInt(storedLikes));
    }

    // Load comments from localStorage if available
    const storedComments = localStorage.getItem(`comments-${id}`);
    if (storedComments !== null) {
      setComments(JSON.parse(storedComments));
    }
  }, [id]); // Only run this effect when id changes

  const handleLike = () => {
    const newLikes = likes + (likes > 0 ? -1 : 1); // Toggle likes
    setLikes(newLikes);
    localStorage.setItem(`likes-${id}`, newLikes); // Store likes in localStorage
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (comment.trim() !== '') {
      const newComments = [...comments, comment];
      setComments(newComments);
      localStorage.setItem(`comments-${id}`, JSON.stringify(newComments)); // Store comments in localStorage
      setComment('');
    }
  };

  return (
    <div className={styles.acceptedMessage}>
      <p>Submitted Details:</p>
      <ul className={styles.submittedDetails}>
        <li><strong>Full Name:</strong> {formData.fullName}</li>
        <li><strong>Email:</strong> {formData.email}</li>
        <li><strong>Type:</strong> {formData.type}</li>
        <li><strong>Headline:</strong> {formData.headline}</li>
        <li><strong>Description:</strong> {formData.description}</li>
      </ul>
      <div className={styles.actions}>
        <button onClick={handleLike} className={styles.actionButton}>
          <span role="img" aria-label="Like">👍</span> {likes > 0 ? 'Unlike' : 'Like'} ({likes})
        </button>
        <button onClick={() => setShowCommentInput(!showCommentInput)} className={styles.actionButton}>
          <span role="img" aria-label="Comment">💬</span> Comment
        </button>
        <button className={styles.actionButton}>
          <span role="img" aria-label="Share">📤</span> Share
        </button>
      </div>
      {showCommentInput && (
        <form onSubmit={handleCommentSubmit} className={styles.commentForm}>
          <input
            type="text"
            value={comment}
            onChange={handleCommentChange}
            placeholder="Type your comment..."
            className={styles.commentInput}
          />
          <button type="submit" className={styles.commentButton}>Post</button>
        </form>
      )}
      <div className={styles.comments}>
        {comments.map((comment, index) => (
          <div key={index} className={styles.comment}>
            {comment}
          </div>
        ))}
      </div>
      <Link to={`/message/${id}`} className={styles.viewMessageLink}>View Message</Link>
    </div>
  );
};

export default AcceptedMessage;
