import React from "react";

function Unlockedoffer({ style, posts }) {
  return (
    <div className="unlockedOffer" id={style}>
      {window.location.href.indexOf("cw") != -1 ||
      window.location.href.indexOf("gift") != -1 ? (
        <p
          dangerouslySetInnerHTML={{
            __html: posts.acf.formtopbannercw,
          }}
        ></p>
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: posts.acf.formtopbanner,
          }}
        ></p>
      )}
    </div>
  );
}

export default Unlockedoffer;
