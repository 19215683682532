import React, { useEffect, useState } from "react";
import axios from "axios";
import emailjs from 'emailjs-com';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

//styles
import "../../components/Shared/Styles/contact.css";

//components
import Footer from "../Footer";
import Navbar from "../Navbar";

//images

function ContactUs({ posts }) {
  const [state, setState] = useState({
    email: "",
    name: "",
    lastName: "",
    help: "",
    message: "",
    orderNo: "",
    success: false,
  });

  const article = {
    data: {
      email: state.email,
      name: state.name,
      lastName: state.lastName,
      help: state.help,
      message: state.message,
      orderNo: state.orderNo,
    },
  };
  
  const location = useLocation();

  useEffect(() => {
    const queryParams = parse(location.search);
    const { lastName } = queryParams;

    // Initialize React Pixel with your Facebook Pixel ID
    ReactPixel.init('959667827420460', undefined, { advancedMatching: { last_name: state.lastName } });

    // Track a page view with the 'PageView' event and include the 'lastName' parameter
    ReactPixel.pageView(undefined, { lastName });
    ReactPixel.track('ContactFormLastname', { lastName });
    // ...other tracking events or customizations

  }, [location.search]);


  
  const sendEmail = (e) => {
    e.preventDefault();
  
    // EmailJS configuration
    const serviceID = 'service_wrrox2l';
    const templateID = 'template_7ntzypg';
    const userID = 'rfsHTqtOh7Xq9H1Li';
  
    // Set up the email parameters
    const emailParams = {
      from_name: state.name,
      to_name: 'MIB',
      last_name: state.lastName,
      email: state.email,
      issue: state.help,
      order_number: state.orderNo,
      message: state.message,
    };

    
  
    // Send the email
    emailjs.send(serviceID, templateID, emailParams, userID)
    .then((response) => {
      console.log('Email sent successfully!', response.status, response.text);
      setState({ ...state, success: true }); // Update success state
    })
    .catch((error) => {
      console.error('Error sending email:', error);
      // Perform any desired error handling
    });
  };
  
  return (
    <>
      <Navbar posts={posts} />
      <div className="contact-us">
        <h2 className="form-title">HOW CAN WE HELP?</h2>
        <p id="form-subtitle">
          We welcome all feedback, comments, and ideas for new and existing
          boxes!
        </p>
        {state.success ? (
  <div>
    <p className="success-message">
      Thank you for contacting us <span>{state.name}</span>! We'll contact you shortly.
    </p>
  </div>
) : (
        <form className="contact-form" onSubmit={sendEmail}>
          <div className="name-lastname">
            <div className="name">
              <div className="label">
                <label htmlFor="name">First Name</label>
                <p>*</p>
              </div>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter First Name"
                required
                value={state.name}
                onChange={(e) => {
                  setState({ ...state, name: e.target.value });
                }}
              />
            </div>
            <div className="last-name">
              <div className="label">
                <label htmlFor="lastName">Last Name</label>
                <p>*</p>
              </div>
              <input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Enter Last Name"
                required
                value={state.lastName}
                onChange={(e) => {
                  setState({ ...state, lastName: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="second-input">
            <div className="label">
              <label htmlFor="email">Email Address</label>
              <p>*</p>
            </div>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter Email Address"
              required
              value={state.email}
              onChange={(e) => {
                setState({ ...state, email: e.target.value });
              }}
            />
          </div>

          <div className="second-input">
            <div className="label">
              <label htmlFor="help">Can we help you with?</label>
              <p>*</p>
            </div>
            <select
              name="help"
              id="help"
              required
              value={state.help}
              onChange={(e) => {
                setState({ ...state, help: e.target.value });
              }}
            >
              <option value="" selected disabled hidden className="options">
                Select an option
              </option>
              <option
                value="Cancel an existing subscription"
                className="options"
              >
                Cancel an existing subscription
              </option>
              <option value="Billing issue" className="options">
                Billing issue
              </option>
              <option value="Trouble accessing account">
                Trouble accessing account
              </option>
              <option value="Report a problem" className="options">
                Report a problem
              </option>
              <option value="Feedback" className="options">
                Feedback
              </option>
              <option value="Other" className="options">
                Other
              </option>
            </select>
          </div>
          <div className="second-input">
            <div className="label">
              <label htmlFor="order">
                What is your order number (optional)
              </label>
            </div>
            <input
              type="text"
              name="orderNo"
              id="order"
              placeholder="Enter Subscription Number"
              value={state.orderNo}
              onChange={(e) => {
                setState({ ...state, orderNo: e.target.value });
              }}
            />
          </div>
          <div className="second-input">
            <div className="label">
              <label htmlFor="desc">Let us know how we can be of help?</label>
              <p>*</p>
            </div>
            <textarea
              id="desc"
              placeholder="Write a message"
              name="message"
              required
              value={state.message}
              onChange={(e) => {
                setState({ ...state, message: e.target.value });
              }}
            ></textarea>
          </div>
          <button
            style={state.success ? { display: "none" } : { display: "block" }}
            type="submit"
            value="Submit Request"
            className="contact-submit"
            formTarget="contactForm"
            onClick={() => {
              // if (
              //   state.email.length > 1 &&
              //   state.lastName.length > 1 &&
              //   state.message.length > 1 &&
              //   state.name.length > 1 &&
              //   state.help.length > 1
              // ) {
              //   axios
              //     .post(
              //       "https://us-central1-instinct-320314.cloudfunctions.net/contactForm",
              //       article
              //     )
              //     .then((response) => setState({ ...state, success: true }));
              // }
            }}
          >
            Submit Request
          </button>
        {state.error && (
      <p className="error-message">
        Please fill in all the required fields correctly!
      </p>
    )}
  </form>
)}
        <p className="available">
          Our MyIrelandBox support team are available Mon - Fri 9am - 5.30pm (Irish time) by email at{" "}
          <br></br>
          <strong>
          <a href={`mailto:help@myirelandbox.com?lastName=${state.lastName}`}>help@myirelandbox.com</a>
          </strong>
        </p>
        <p className="respond">
          {" "}
          We will respond to all issues raised within 24 hours.
        </p>
        <p className="exclude"> (excluding weekends and public holidays)</p>
      </div>
      <iframe
        name="contactForm"
        style={{ display: "none", width: "0", height: "0" }}
      ></iframe>
      <Footer />
    </>
  );
}

export default ContactUs;
