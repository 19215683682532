import React from "react";
import Lottie from "react-lottie-player";
import loaderLeaf from "../../assets/leaf loop2.json";
function Loader() {
  return (
    <div className="main-loader">
      {/* <img src={loaderLeaf} /> */}
      <Lottie
        loop
        animationData={loaderLeaf}
        play
        style={{ width: 150, height: 150 }}
      />
    </div>
  );
}

export default Loader;
