import React, { useState, useRef, useEffect } from 'react';
import styles from './Stories.module.css';
import AcceptedMessage from './AcceptedMessage';
import Footer from '../../Footer';
import Navbar from '../../Navbar';
import emailjs from 'emailjs-com'; // Import MailJS library
function Stories({posts}) {
  const [showPopup, setShowPopup] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    type: '',
    headline: '',
    description: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        closePopup();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setFormData({
      fullName: '',
      email: '',
      type: '',
      headline: '',
      description: ''
    });
    setSubmitted(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  
    const submissionId = Date.now().toString(); // Generate a unique submission ID
  
    const acceptLink = `YOUR_APPROVE_ENDPOINT?submissionId=${submissionId}`; // Include submission ID in accept link
    const rejectLink = `YOUR_REJECT_ENDPOINT?submissionId=${submissionId}`; // Include submission ID in reject link
  
    // Construct email body
    const emailBody = `
      Hi Katharine,
      We just received a new submission on "Irish stories". Please review it below:
      Full Name: ${formData.fullName}
      Email: ${formData.email}
      Type: ${formData.type}
      Headline: ${formData.headline}
      Description: ${formData.description}
      To approve, please click <a href="${acceptLink}">here</a>!
      To reject, please click <a href="${rejectLink}">here</a>!
      Thank you,
    `;
  
    // Send email using MailJS
    emailjs.send('service_9bvoxlb', 'template_jis1ago', {
      user_id: 'zOSJXf-zvBg0Thste',
      subject: 'You have a new story to review!',
      to_email: 'stories@myirelandbox.com',
      message: emailBody,
    })
    .then((response) => {
      console.log('Email sent successfully:', response.status, response.text);
    })
    .catch((error) => {
      console.error('Error sending email:', error);
    });
  
    // Store the form data and submission ID in localStorage
    localStorage.setItem(`message-${submissionId}`, JSON.stringify(formData));
  };
  
  const formattedHeadline = formData.headline.replace(/\s+/g, '-').toLowerCase();


  const getSubmissionIdFromLocalStorage = () => {
    // Implement logic to retrieve the submission ID from localStorage
    // For example:
    const submissionId = localStorage.getItem('submissionId');
    return submissionId;
  };
  
  // Function to fetch status of submission from backend
  const getStatusFromBackend = (submissionId) => {
    // Implement logic to fetch the status of the submission from your backend
    // For example:
    // You might make an AJAX request to your backend API
    // and return the status from the response
    // This is just a placeholder, you should replace it with your actual implementation
    return 'accepted'; 
  };
  useEffect(() => {
    // Polling or real-time updates to check the status of submission
    const interval = setInterval(() => {
      // Check submission status using submission ID
      const submissionId = getSubmissionIdFromLocalStorage(); // Implement this function to retrieve submission ID from localStorage
      if (submissionId) {
        const submissionStatus = getStatusFromBackend(submissionId); // Implement this function to get submission status from backend
        if (submissionStatus === 'accepted') {
          setAccepted(true);
        }
      }
    }, 5000); // Polling interval: every 5 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, []);

  
  return (
       <>
        <Navbar posts={posts} />
    <div className={styles.container}>
      <button onClick={openPopup} className={styles.button}>
        Add Your Description
      </button>

      {showPopup && (
        <div className={styles.popupOverlay} ref={popupRef}>
          <div className={styles.popupContent}>
            <button onClick={closePopup} className={styles.closeButton}>X</button>

            {submitted ? (
              <div className={styles.successMessage}>
                <span className={styles.icon}>✅</span>
                <p>Your story is being reviewed by the MyIrelandBox founding team, and we will let you know once it's shared.</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <label className={styles.formLabel}>
                  Full Name:
                  <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required className={styles.inputField} />
                </label>
                <label className={styles.formLabel}>
                  Email:
                  <input type="email" name="email" value={formData.email} onChange={handleChange} required className={styles.inputField} />
                </label>
                <label className={styles.formLabel}>
                  Type:
                  <input type="text" name="type" value={formData.type} onChange={handleChange} className={styles.inputField} />
                </label>
                <label className={styles.formLabel}>
                  Headline:
                  <input type="text" name="headline" value={formData.headline} onChange={handleChange} required className={styles.inputField} />
                </label>
                <label className={styles.formLabel}>
                  Description:
                  <textarea name="description" value={formData.description} onChange={handleChange} required className={`${styles.inputField} ${styles.textareaField}`} />
                </label>
                <button type="submit" className={styles.button}>Submit</button>
              </form>
            )}
          </div>
        </div>
      )}


  {accepted ? (
    <div className={styles.aprrovedStories}>
      {Object.keys(localStorage).filter(key => key.startsWith('message-')).map((key, index) => (
        <AcceptedMessage key={index} formData={JSON.parse(localStorage.getItem(key))} id={key.split('-')[1]} />
      ))}
    </div>
  ) : (
  <p></p>
  )}

    </div>
      <Footer />
       </>
  );
} 

export default Stories;
