import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../../Footer";
import Navbar from "../../../Navbar";
import FacebookGroup from "../Homepage/FacebookGroup";
import AllArticles from "./AllArticles";
import Announcments from "./Announcments";
import Community from "./Community";
import Guide from "./Guide";
import "./secondBlog.css"
import Travel from "./Travel";
import LazyLoad from 'react-lazyload';

const SecondBlog = ({posts}) =>{ 
    const [article, setArticle] = useState(0);
    const [blog, setBlog] = useState({
        posts: [],
        isLoaded: false,
      });
      const [featured, setFeatured] = useState({
        posts: [],
        isLoaded: false,
      });
    
      useEffect(() => {
        axios.get("https://cms.myirelandbox.com/wp-json/wp/v2/blog").then((res) => {
          setBlog({
            ...blog,
            posts: res.data,
            isLoaded: true,
          });
        });
        axios
          .get(
            "https://cms.myirelandbox.com/wp-json/wp/v2/blog?category_slug=featured"
          )
          .then((res) => {
            setFeatured({
              ...featured,
              posts: res.data,
              isLoaded: true,
            });
          })
          .catch((err) => console.log(err));
      }, []);

      const articleOption = [
        <AllArticles />,
        <Travel />,
        <Guide />,
        <Community />,
        <Announcments />,
      ]
    return (
        <>
        <Navbar posts={posts} />
            <div className="blog-container">
            <ul className="blog-tag">
               <li onClick={() => setArticle(0)} className={article === 0 ? "tab-active" : ""}>All Articles</li>
               <li onClick={() => setArticle(1)} className={article === 1 ? "tab-active" : ""}>Travel</li>
               <li onClick={() => setArticle(2)} className={article === 2 ? "tab-active" : ""}>Guide</li>
               <li onClick={() => setArticle(3)} className={article === 3 ? "tab-active" : ""}>Community</li>
               <li onClick={() => setArticle(4)} className={article === 4 ? "tab-active" : ""}>Announcments</li>
           </ul>
           <div className="all-articles-height">{articleOption[article]}</div>
            </div>
            <LazyLoad height={200}>
        <FacebookGroup posts={posts} />
        </LazyLoad>
        <Footer />
        </>
    )
}

export default SecondBlog;