import React, { useState } from "react";
import emailarrow from "../../assets/emailarrow.png";
import LazyLoad from 'react-lazyload';


function Subscribe({ posts }) {
  const [mailError, setMailError] = useState(true);
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState("");
  const validateEmail = (email) => {
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);

    if (emailRegex.test(email) === false) {
      setMailError(false);
    } else {
      setMailError(true);
    }
  };
  // console.log(mailError);
  return (
    <div className="subscribe-main-wrapper">
      <div className="subscribe-card">
        <div className="subscribe-card-text">
          <h1 className="subscribe-email-address">
            Learn Gaelic, get your hands on secret Irish family recipes, and see
            the best of ireland in photos ~ ~ and that’s just the beginning of
            what you will discover within this wonderful e-booklet!
          </h1>
          <p>
            Enter your email to unlock your free My Ireland E-Booklet, drafted
            by us, for you! Enjoy it.
          </p>
          <div className="footer-subscribe subscribe-subscribe">
            <form
              action="https://cms.myirelandbox.com/wp-content/submitNewsletter.php"
              method="POST"
              className="footer-form"
            >
              <input
                className="email email-subscribe"
                id="email-subscribe"
                type="email"
                placeholder="Enter your email"
                name="email"
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
                }}
              ></input>

              <iframe
                name="footerEmail"
                style={{ display: "none", width: "0", height: "0" }}
              ></iframe>
              <button
                className="footer-submit-button"
                formTarget="footerEmail"
                disabled={mailError === false}
                onClick={() => {
                  if (mailError === true && email.length > 1) {
                    setMessage(true);
                    window.open(
                      "https://cms.myirelandbox.com/free-e-book/",
                      "_blank"
                    );
                  } else {
                    setMessage(false);
                  }
                }}
              >
                Unlock    <LazyLoad height={200}><img src={emailarrow} alt={"tt"}></img></LazyLoad>
              </button>
            </form>
          </div>
          {!mailError ? (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                textAlign: "left",
                width: "300px",
                //   position: "absolute",
              }}
            >
              Enter a valid email!
            </p>
          ) : null}
          {message === true && mailError === true ? (
            <p
              style={{
                color: "#1b6047",
                fontSize: "13px",
                textAlign: "left",
                width: "300px",
                //   position: "absolute",
              }}
            >
              Thank you for subscribing, your free E-book will be opened in new
              tab shortly! 😊 😊
            </p>
          ) : (
            ""
          )}
        </div>
        <img src="https://cms.myirelandbox.com/wp-content/uploads/2022/05/booklet.png" alt="booklet" />
      </div>
    </div>
  );
}

export default Subscribe;
