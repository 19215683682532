import React from "react";
import { Link } from "react-router-dom";

import banner from "../../../assets/ltbanner.png";
import banner2 from "../../../assets/lettersbn.png";
import Logo from "../../../assets/logo.svg";
const LettersBanner = (props) =>{
    return (
            <div className="ltbanner-bck">
                    <div className="letters-container">
                         <div className="ltbanner-content">
                               <h1>Welcome to My Letters from Ireland, a new way to connect with Ireland every month!</h1>
                               <p>Immerse yourself in a journey of discovery of Irish tradition, culture and heritage. Delivered every month, My Letters from Ireland are the perfect way to forge your connection with Ireland.</p>
                               <p   className="button1 letters-btn1"  onClick={props.find3}>Join Now</p>

                               <div className="lt-absolute">
                                <p>Created by
the founders of</p>
                                <img src={Logo} alt="#" />
                               </div>
                         </div>
                         <div className="letters-banner">
                              <img src={banner} alt="banner"  className="lt-des"/>
                              <img src={banner2} alt="banner"  className="lt-mob" />
                         </div>
                    </div>
            </div>
    )
}

export default LettersBanner;