import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Turn as Hamburger } from "hamburger-react";
import Slide from "react-reveal/Slide"; 
import LazyLoad from 'react-lazyload';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Autoplay } from "swiper/modules"; 


//styles
import "../components/Shared/Styles/navbar.css";

//components
import CountDown from "./Shared/CountDown";
import FreeButlers from "../components/Shared/FreeButlers";

//images
import Logo from "../assets/logo.svg";
import years from "../assets/12.svg";
function Navbar({ posts }) {
  const [visible, setVisible] = React.useState(false);
  const [style, setStyle] = useState("cont");
  const [paragraph, setParagraph] = useState("");
  const [dropdown, setDropdown] = useState(false);

  const changeStyle = () => {
    setStyle("fixed");
  };
  const changeFixed = () => {
    setStyle("");
  };
  const button = () => {
    if (visible) {
      return (
        <button
          onClick={() => {
            setVisible(!visible);
            changeFixed();
          }}
          className="burger-icon"
        >
          <Hamburger size={25} />
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            setVisible(!visible);
            changeStyle();
          }}
          className="burger-icon"
        >
          <Hamburger size={25} />
        </button>
      );
    }
  };
  useEffect(() => {
    if (window.location.pathname !== "/free-tree") {
      setParagraph(
        <p
          className="offPragraph"
          dangerouslySetInnerHTML={{
            __html: posts.acf.topnotice,
          }}
        ></p>
      );
    } else if (window.location.pathname !== "/") {
      setParagraph(
        <p
          className="offPragraph"
          dangerouslySetInnerHTML={{
            __html: posts.acf.topnotice2,
          }}
        ></p>
      );
    } else {
      setParagraph(
        <p
          className="offPragraph"
          dangerouslySetInnerHTML={{
            __html: posts.acf.topnotice,
          }}
        ></p>
      );
    }
  }, [window.location.pathname]);
  const fruits = [
    <a href="https://www.myirelandboxstore.com/">Home</a>,
    <a href="https://www.myirelandboxstore.com/collections/all">Shop All</a>,

    <a href="https://www.myirelandboxstore.com/collections/art-printed-goods">
      Art & Prints
    </a>,
    <a href="https://www.myirelandboxstore.com/collections/books">Books</a>,
    <a href="https://www.myirelandboxstore.com/collections/boutique-irish-gifts">
      Boutique Irish Gifts
    </a>,

    <a href="https://www.myirelandboxstore.com/products/may-2022-myirelandbox-within-the-irish-cottage">
      Within the Irish Cottage Curation
    </a>,

    <a href="https://www.myirelandboxstore.com/collections/food-drinks-snacks">
      Food, Drinks & Snacks
    </a>,
    <a href="https://www.myirelandboxstore.com/collections/homeware">
      Homeware
    </a>,
  ];
  return (
    <div style={{ width: "100%", backgroundColor: "white" }}>
      <Link
        to={
          window.location.href.indexOf("cw") !== -1
            ? "/cw/form"
           
            : "/get-started"
        }
        style={{ textDecoration: "none" }}
      >

{/* <Swiper
          modules={[Navigation, A11y, Autoplay]}
          
          className=" mySwiper swiperWrapper swiperWrapperTwo"
          spaceBetween={0}
          watchOverflow="hidden"
          scrollbar={{ draggable: false }}
          // onSwiper={(swiper) => console.log(swiper)}
          // slideToClickedSlide={true}
          // preventClicksPropagation={false}
          // preventClicks={false}
          // allowTouchMove={false}
          clickable={true}
          centeredSlides={true}
          loop={true}
          speed={8000}
          autoplay={{
            delay: 0,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            600: {
              slidesPerView: 1,
              spaceBetween: -10,
            },
            800: {
              slidesPerView: 1,
              spaceBetween: -10,
            },

            1200: {
              slidesPerView: 1.4,
            },
          }}
        >
          <div className="swiper-wrapper swiperstart">
        
                <SwiperSlide className="swiperstop">
                <div id="offBody" className={style}>
          { 
          (window.location.pathname === "/cw" || window.location.pathname === "/cw/plans") ? 
           <p
            className="offPragraph"
           
          >{posts.acf.topnoticeCw}</p> 
          :
          window.location.href.indexOf("gift") !== -1 ? (
            <p
            className="offPragraph"
            dangerouslySetInnerHTML={{
              __html: posts.acf.topnoticeTwo,
            }}
          ></p>
          ) : (
            paragraph
          )}
        </div>
                </SwiperSlide>
                <SwiperSlide className="swiperstop">
                <div id="offBody" className={style}>
          { 
          (window.location.pathname === "/cw" || window.location.pathname === "/cw/plans") ? 
           <p
            className="offPragraph"
           
          >{posts.acf.topnoticeCw}</p> 
          :
          window.location.href.indexOf("gift") !== -1 ? (
            <p
            className="offPragraph"
            dangerouslySetInnerHTML={{
              __html: posts.acf.topnoticeTwo,
            }}
          ></p>
          ) : (
            paragraph
          )}
        </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-wrapper  swiperstop">
                <div id="offBody" className={style}>
          { 
          (window.location.pathname === "/cw" || window.location.pathname === "/cw/plans") ? 
           <p
            className="offPragraph"
           
          >{posts.acf.topnoticeCw}</p> 
          :
          window.location.href.indexOf("gift") !== -1 ? (
            <p
            className="offPragraph"
            dangerouslySetInnerHTML={{
              __html: posts.acf.topnoticeTwo,
            }}
          ></p>
          ) : (
            paragraph
          )}
        </div>
                </SwiperSlide>
                <SwiperSlide className="swiperstop">
                <div id="offBody" className={style}>
          { 
          (window.location.pathname === "/cw" || window.location.pathname === "/cw/plans") ? 
           <p
            className="offPragraph"
           
          >{posts.acf.topnoticeCw}</p> 
          :
          window.location.href.indexOf("gift") !== -1 ? (
            <p
            className="offPragraph"
            dangerouslySetInnerHTML={{
              __html: posts.acf.topnoticeTwo,
            }}
          ></p>
          ) : (
            paragraph
          )}
        </div>
                </SwiperSlide>
           
           
          </div>
        </Swiper> */}
       <div id="offBody" className={style}>
          { 
          (window.location.pathname === "/cw" || window.location.pathname === "/cw/plans") ? 
           <p
            className="offPragraph"
           
          >{posts.acf.topnoticeCw}</p> 
          :
          window.location.href.indexOf("gift") !== -1 ? (
            <p
            className="offPragraph"
            dangerouslySetInnerHTML={{
              __html: posts.acf.topnotice,
            }}
          ></p>
          ) : (
            paragraph
          )}
        </div>
      </Link>
      <CountDown posts={posts} style={style} />

      {visible && (
        <Slide duration={500} top>
          <div className="mobile-navbar">
            <p className="hoverable">
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/aboutus"
                    : "/aboutus"
                }
              >
                About
              </Link>
            </p>
            <p className="hoverable">
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/peek-inside"
                    : "/peek-inside"
                }
              >
Past Boxes              </Link>
            </p>
            <div className="navbar-dropwdown-relative">
              <div
                className="dropdown-links"
                style={dropdown ? { display: "block" } : { display: "none" }}
              >
                {fruits.map((e) => e)}
              </div>
            </div>
            <p className="hoverable">
              <a
                href="https://checkout.myirelandbox.com/account/auth/login?store=myirelandbox&redirect_url=https%3A%2F%2Fwww.myirelandbox.com%2Fsubscribe-now"
                target="_blank"
              >
                Login
              </a>
            </p>{" "}
            <div className="bannerBtns" id="bannerbtns-mobilenav">
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/form"
                    : "/get-started"
                }
                className="button1 hoverable"
                id="joinbutton"
              >
                Join MyIrelandBox
              </Link>
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/gifting-form"
                    : "/gifting-form"
                }
                className="button2 hoverable"
                id="giftbutton"
              >
                Give a Gift
              </Link>
            </div>
          </div>
        </Slide>
      )}

      <div id="navbar" className={style}>
        
        <div className="logo">
          <Link
            to={
              window.location.href.indexOf("cw") !== -1
                ? "/cw"
                : "/"
            }
          >
            <LazyLoad height={200}>
            <img src={Logo} alt="Logo" />
            </LazyLoad>
          </Link>

          <div className="navbar-shirit shirit-desktop"> <p>Established 2013. Celebrating</p> <img src={years} alt="10-years" /> <p>in business! </p></div>
        </div>
        <div className="allLinks">
          <Link
            to={
              window.location.href.indexOf("cw") !== -1
                ? "/cw/aboutus"
                : "/aboutus"
            }
            className="links hoverable"
          >
            About
          </Link>
          
          {/* <Dropdown>
            <span className="orange-label"></span>
            <Dropdown.Toggle id="dropdown-basic">Shop</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="https://www.myirelandboxstore.com/">
                Home
              </Dropdown.Item>
              <Dropdown.Item href="https://www.myirelandboxstore.com/collections/all">
                Shop All
              </Dropdown.Item>

              <Dropdown.Item href="https://www.myirelandboxstore.com/collections/art-printed-goods">
                Art & Prints
              </Dropdown.Item>
              <Dropdown.Item href="https://www.myirelandboxstore.com/collections/books">
                Books
              </Dropdown.Item>
              <Dropdown.Item href="https://www.myirelandboxstore.com/collections/boutique-irish-gifts">
                Boutique Irish Gifts
              </Dropdown.Item>
              
              kjo ka qen hidden edhe parase me e hide krejt Shop dropdown
              <Dropdown.Item href="https://www.myirelandboxstore.com/products/may-2022-myirelandbox-within-the-irish-cottage">
                Within the Irish Cottage Curation
              </Dropdown.Item>

              <Dropdown.Item href="https://www.myirelandboxstore.com/collections/food-drinks-snacks">
                Food, Drinks & Snacks
              </Dropdown.Item>
              <Dropdown.Item href="https://www.myirelandboxstore.com/collections/homeware">
                Homeware
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <Link
            to={
              window.location.href.indexOf("cw") !== -1
                ? "/cw/peek-inside"
                : "/peek-inside"
            }
            className="links hoverable"
          >
Past Boxes          </Link>
          <a
            href="https://checkout.myirelandbox.com/account/auth/login?store=myirelandbox&redirect_url=https%3A%2F%2Fwww.myirelandbox.com%2Fsubscribe-now"
            target="_blank"
            className="links hoverable"
          >
            Login
          </a>
          <Link
            to={
              window.location.href.indexOf("cw") !== -1
                ? "/cw/form"
                : "/get-started"
            }
            className="subscribes"
          >
            {" "}
            <button id="navbar-btn" className="hoverable">
              SUBSCRIBE
            </button>
          </Link>
        </div>

        <div className="burger-icon-button">{button()}</div>
      </div>
      <div className="navbar-shirit shirit-mobile"> <p>Established 2013. Celebrating</p> <img src={years} alt="10-years" /> <p>in business! </p></div>
      <FreeButlers posts={posts} />
      
    </div>
  );
}

export default Navbar;
