import React, { useEffect, useState } from "react";
import Zoom from "react-reveal/Zoom";
import thebadge from "../../../assets/thebadge.png";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import LazyLoad from 'react-lazyload';


function UserName({ formData, setFormData ,posts}) {
  useEffect(() => {}, []);

  const validateUsername = (username) => {
    const strongRegex = new RegExp("^[a-z A-Z'-]+$");

    if (!strongRegex.test(username)) {
      setFormData({ ...formData, nameValid: false, name: username });
    } else {
      setFormData({ ...formData, nameValid: true, name: username });
    }
  };
  const validateLastname = (username) => {
    const strongRegex = new RegExp("^[a-z A-Z'-]+$");

    if (!strongRegex.test(username)) {
      setFormData({ ...formData, lastNameValid: false, lastName: username });
    } else {
      setFormData({ ...formData, lastNameValid: true, lastName: username });
    }
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // document.getElementById("body").style.opacity = "0.1";
    document.getElementById("modal-content").classList.add("modal-fade-in");
  }

  function closeModal() {
    setIsOpen(false);
  }
  console.log(posts);

  return (
    <>
      
      
    <Zoom delay={100} duration={200}>
      <div className="thebade" onClick={openModal} >
      <LazyLoad height={200}>
        <img src={posts.acf.modalbadge} alt="thebadge"></img>
        </LazyLoad>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style=}
        id="modal-content"
        contentLabel="Example Modal"
      >
    <div className="draw-offer" >
      <div onClick={closeModal} id="closemodal-badge">X</div>
      <LazyLoad height={200}>
        <img id="badge2" src={posts.acf.modalbadge} alt="thebadge"></img>
        </LazyLoad>
      <p   dangerouslySetInnerHTML={{
            __html: posts.acf.modalcontent,
          }}></p>
    <button className="modalbtn" onClick={closeModal}>
      Continue to Enter
    </button>
      </div>
      </Modal>
      <div>
        
        <div className="userInfo">
          <div className="input-container2">
            <input
              type="text"
              required
              value={formData.name}
              onChange={(e) => {
                validateUsername(e.target.value);
              }}
            />
            <label className={formData.name && "filled"}>First Name</label>
          </div>

          <span></span>
          <div className="input-container2">
            <input
              type="text"
              required
              value={formData.lastName}
              onChange={(e) => {
                validateLastname(e.target.value);
              }}
            />
            <label className={formData.lastName && "filled"}>Last Name</label>
          </div>
        </div>
        <div
          className="errorDiv"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "500px",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              color: "red",
              fontSize: "12px",
              textAlign: "left",
              width: "300px",
            }}
          >
            {formData.nameValid ? "" : "Enter a valid name!"}
          </p>

          <p
            style={{
              color: "red",
              fontSize: "12px",
              textAlign: "left",
              width: "300px",
            }}
          >
            {formData.lastNameValid ? "" : "Enter a valid last name!"}
          </p>
        </div>
      </div>
    </Zoom>
    </>
  );
}

export default UserName;
