import React, { useRef } from "react";
import FacebookGroup from "../Shared/Pages/Homepage/FacebookGroup";
import "../../components/Shared/Styles/Homepage/letters.css";
import LettersNav from "../Shared/Letters/nav";
import LettersBanner from "../Shared/Letters/banner";
import LettersWork from "../Shared/Letters/works";
import connect from "../../assets/kat.png"
import LetterPlan from "../Shared/Letters/plan";
const Letters = ({ posts, setFounder, boxes, newHp}) => {
    const sendToView = useRef(null)
    const sendToView2 = useRef(null)
    const sendToView3 = useRef(null)

    const handleClick = () => {
        sendToView.current.scrollIntoView({ behavior: 'smooth' });
      };
      const handleClick2 = () => {
        sendToView2.current.scrollIntoView({ behavior: 'smooth' });
      };
      const handleClick3 = () => {
        sendToView3.current.scrollIntoView({ behavior: 'smooth' });
      };
    
    return (
        <>
        
            <LettersNav  find={handleClick}  find2={handleClick2} find3={handleClick3}/>
           <LettersBanner find3={handleClick3}/>

           <LettersWork sendToView={sendToView}  find3={handleClick3}/>


           <div className="letters-container letters-connect" ref={sendToView2}>
                 <div>
                     <img src={connect} alt="#"/>
                 </div>

                 <div>
                    <h1>The mail you'll look forward to every month!</h1>
                    <p>Tired of getting promotional mail, or general mail that you don't love? Well, this little surprise will surely  get you excited every month. My Letters from Ireland are designed to keep your connection to Ireland strong. We’ll take you somewhere special every month within your letter from Ireland, you’ll learn about our ancient Irish traditions during that month, nature in Ireland, you’ll receive one of our favorite family Irish recipes, a postcard and our 4-6 page letter.</p>
                 </div>
           </div>

           <LetterPlan sendToView={sendToView3}/>
        <FacebookGroup posts={posts}/>  
        </>
    )
}

export default Letters;