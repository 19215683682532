import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import Modal from 'react-modal';
import { Link } from "react-router-dom";

import all from "../../../../assets/gl.png";
import allMob from "../../../../assets/gl.png";

const Craft = ({ posts }) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      // document.getElementById("body").style.opacity = "0.1";
      document.getElementById("modal-content2").classList.add("modal-fade-in");
    }
  
    function closeModal() {
      setIsOpen(false);
    }
  
  
  
  
  
    return  (
        <div className="third-section">

        <div className="nextship">
          <div className="ship-left">
            <p className="txt-head">The Golden Shamrock Prize</p>
            <h1 className="handbag-title" style={{fontFamily:"The new Elegance"}}>Breacadh an Lae Pendant: A Timeless Tribute to Ireland’s Dawn


</h1>
            <div className="ship-right mobile-ship">
          <LazyLoad height={200}>
            <img src={all} className="txt-img desPhoto" />
            <img src={allMob} className="txt-img mobilePhoto" />

            </LazyLoad>
          </div>
            <p className="txt-paragraph">This stunning sterling silver pendant, and matching earrings designed by the renowned Brian de Staic, capture the essence of a new dawn. Breacadh an Lae—meaning "the break of day"—is more than a name; it’s a beautiful reminder of renewal, hope, and life’s endless cycle of growth. Each piece is handcrafted here in Dingle, County Kerry, making it as unique and meaningful as the morning light that inspired it.
            <br/>
            We will choose a winner from our active members at the beginning of January and ship it off to you from Dingle Ireland if it is indeed you who wins it.


            </p>
             <div className="handbag-display">
             <Link to={window.location.href.includes("christmas-ecard") ? "/gifting-form" :"/get-started"}  >{window.location.href.includes("christmas-ecard") ? "Gift to Enter Draw" :"Subscribe to Enter Draw"} </Link>
             <p onClick={openModal} className="hoverable">See Rules</p>
             </div>
          </div>
          <div className="ship-right desktop-ship">
          <LazyLoad height={200}>
            <img src={all} className="txt-img" />
            </LazyLoad>
          </div>

          <div className="thebade" onClick={openModal} >
        <LazyLoad height={200}>
             <img src={posts.acf.modalBadge} id="badge-btn-image" alt="badgeimage"></img>
        </LazyLoad>
      </div>
        </div>

            
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        id="modal-content2"
        contentLabel="Example Modal"
      >
      <div className="draw-offer-homepage" >
        <div onClick={closeModal} id="closemodal-badge2">X</div>
            <LazyLoad height={200}>
        <img id="badge3" src={posts.acf.modalBadge} alt="badgeimage
        "></img>
        </LazyLoad>
          <p   dangerouslySetInnerHTML={{
            __html: posts.acf.modalcontent2,
          }}></p>
          <Link to="/get-started">
        <button className="modalbtn " onClick={closeModal}>
          Continue to Enter
        </button>
        </Link>
        </div>
      </Modal>

     </div>
    )
}

export default Craft;