import React, { useEffect, useState } from "react";
import Zoom from "react-reveal/Zoom"; 

function UserName({ formData, setFormData }) {

  useEffect(() => {}, []);

  const validateUsername = (username) => {
    const strongRegex = new RegExp("^[a-z A-Z'-]+$");

    if (!strongRegex.test(username)) {
      setFormData({ ...formData, nameValid: false, name: username });
    } else {
      setFormData({ ...formData, nameValid: true, name: username });
    }
  };
  const validateLastname = (username) => {
    const strongRegex = new RegExp("^[a-z A-Z'-]+$");

    if (!strongRegex.test(username)) {
      setFormData({ ...formData, lastNameValid: false, lastName: username });
    } else {
      setFormData({ ...formData, lastNameValid: true, lastName: username });
    }
  };
  return (
    <Zoom delay={100} duration={200}>
      <div>
        <div className="userInfo">
          <div className="input-container2">
            <input
              type="text"
              required
              value={formData.name}
              onChange={(e) => {
                validateUsername(e.target.value);
              }}
            />
            <label className={formData.name && "filled"}>First Name</label>
          </div>

          <span></span>
          <div className="input-container2">
            <input
              type="text"
              required
              value={formData.lastName}
              onChange={(e) => {
                validateLastname(e.target.value);
              }}
            />
            <label className={formData.lastName && "filled"}>Last Name</label>
          </div>
        </div>
        <div
          className="errorDiv"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "500px",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              color: "red",
              fontSize: "12px",
              textAlign: "left",
              width: "300px",
            }}
          >
            {formData.nameValid ? "" : "Enter a valid name!"}
          </p>

          <p
            style={{
              color: "red",
              fontSize: "12px",
              textAlign: "left",
              width: "300px",
            }}
          >
            {formData.lastNameValid ? "" : "Enter a valid last name!"}
          </p>
        </div>
      </div>
    </Zoom>
  );
}

export default UserName;
