import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import ReactHtmlParser from "react-html-parser";

function CookiePolicy({ posts, homepage }) {
  return (
    <div className="cookies-policy">
      <Navbar posts={homepage} />
      <div className="cookies-div">
        {ReactHtmlParser(posts.content.rendered)}
      </div>

      <Footer />
    </div>
  );
}

export default CookiePolicy;
