import { post } from "jquery";
import React from "react";

function Unlockedoffer({ style, posts }) {
  return (
    <>
    
    {/* {console.log("postsAAAAAAAAAAAAAAA",posts.acf)} */}
    <div className="unlockedOffer" id={style}>
      {window.location.href.indexOf("cw") != -1 ||
      window.location.href.indexOf("gift") != -1 ? (
        <p  
          dangerouslySetInnerHTML={{
            __html: posts.acf.formtopbannergift,
          }}
        ></p>
      ) : (
        <p id="aa"
          dangerouslySetInnerHTML={{
            __html: posts.acf.formtopbannergiftcw,
          }}
        ></p>
      )}
    </div></>
  );
}

export default Unlockedoffer;
