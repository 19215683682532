import React from "react";

//CSS
import "./joinFacebookGroup.css";

//IMG
function JoinFacebookGroup({ plans }) {
  return (
    <div className="join-group-div">
      <div className="facebook">
        <img
          src={plans.acf.planfirst.facebook.facebookimg.url}
          alt="facebook-logo"
        />
        <h1>Join our members group on Facebook</h1>
      </div>
      <div className="join-group-info">
        <p>
          I invite you to hop on to our Facebook Group to learn from the
          experience of thousands of MyIrelandBox members. We also share fun
          trips around Ireland, lessons on Gaelic and more!
        </p>
        <a href="https://www.facebook.com/groups/MyIrelandBox" target="_blank">
          Join Group
        </a>
      </div>
    </div>
  );
}

export default JoinFacebookGroup;
