import React, { useEffect, useState } from "react";

import ic1 from "../../../../assets/ic1.png";
import ic2 from "../../../../assets/ic2.png";
import ic3 from "../../../../assets/ic3.png";
import ic4 from "../../../../assets/ic4.png";
import ic5 from "../../../../assets/ic5.png";
import ic6 from "../../../../assets/ic6.png";
const Circle = () => {
    const [tab,setTab] = useState(0);
    const [autoIncrement, setAutoIncrement] = useState(true);

    const circle = [
        {
            title:"10 Years ",
            description:"in business. The longest standing Irish subscription box in the world!"
        },
        {
            title:"18.5K Members",
            description:"in our vibrant Facebook community"
        },
        {
            title:"A+",
            description:"customer service team"
        },
        {
            title:"75,000+",
            description:"boxes shipped around the US and Canada"
        },
        {
            title:"10,000+",
            description:"forging connections between Irish expats around the world"
        },
        {
            title:"€3M +",
            description:"worth of support for independent  Irish makers"
        },
    ]
  
    useEffect(() => {
        const intervalId = setInterval(() => {
          if (autoIncrement) {
            setTab(prevTab => (prevTab + 1) % 6);
          }
        }, 2000);

        return () => clearInterval(intervalId);
      }, [autoIncrement]);
    return (
        <>
          <h1 className="circle-heading">Why MyIrelandBox?</h1>
        <div className="why-ireland">
            <div className="big-circle">
                <h1>{circle[tab].title}</h1>
                <p>{circle[tab].description}</p>
            </div>
            <div className="circle-display">
          
                    <img src={ic1} alt="ic1"  onClick={() => {setTab(0);setAutoIncrement(false);}} className={`circle-bck ${tab === 0 ? "circle-active" : ""}`}/>
             
             
                <img src={ic2} alt="ic3"  onClick={() => {setTab(1);setAutoIncrement(false);}} className={`circle-bck ${tab === 1 ? "circle-active" : ""}`}/>
         
                
                <img src={ic3} alt="ic3"   onClick={() => {setTab(2);setAutoIncrement(false);}} className={`circle-bck ${tab === 2 ? "circle-active" : ""}`}/>
            
                <img src={ic4} alt="ic4"  onClick={() => {setTab(3);setAutoIncrement(false);}} className={`circle-bck ${tab === 3 ? "circle-active" : ""}`}/>
            
                
                <img src={ic5} alt="ic5"   onClick={() => {setTab(4);setAutoIncrement(false);}} className={`circle-bck ${tab === 4 ? "circle-active" : ""}`}/>
             
              
                <img src={ic6} alt="ic6"  onClick={() => {setTab(5);setAutoIncrement(false);}} className={`circle-bck ${tab === 5 ? "circle-active" : ""}`}/>
             
            </div>
        </div>
        </>
    )
}

export default Circle;