import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import ReactHtmlParser from "react-html-parser";

function RefundPolicy({ posts, homepage }) {
  return (
    <div className="refund-policy">
      <Navbar posts={homepage} />
      <div className="refund-div">
        {ReactHtmlParser(posts.content.rendered)}
      </div>
      <Footer />
    </div>
  );
}

export default RefundPolicy;
