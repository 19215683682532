import React, { useState } from "react";
import Zoom from "react-reveal/Zoom"; 
import { Link } from "react-router-dom";

function EmailAddress({ formData, setFormData }) {
  const [acceptedT, setAcceptedT] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);

    if (emailRegex.test(email) === false) {
      setFormData({ ...formData, mailError: false, email: email });
    } else {
      setFormData({ ...formData, mailError: true, email: email });
    }
  };

  const clickAgreement = () => {
    if (acceptedT === false) {
      setAcceptedT(true);
    } else {
      setAcceptedT(false);
    }
  };

  return (
    <Zoom delay={100} duration={200}>
      <div className="emailAddress">
        <div className="input-container">
          <input
            type="email"
            value={formData.email}
            required
            onChange={(e) => {
              validateEmail(e.target.value);
            }}
          />
          <label className={formData.email && "filled"}>
            Email of Subscription Purchaser
          </label>
        </div>
        {!formData.mailError ? (
          <p
            style={{
              color: "red",
              fontSize: "12px",
              textAlign: "left",
              width: "300px",
            }}
          >
            Enter a valid email!
          </p>
        ) : null}

        <div>
          <div className="agreement">
            <input
              type="checkbox"
              name="termsPrivacy"
              className="checkbox"
              required
              onClick={clickAgreement}
              value={false}
              disabled={formData.email === null}
              checked={formData.accepted}
              onChange={() => {
                setFormData({ ...formData, accepted: !acceptedT });
              }}
            />
            <p>
              I agree to the{" "}
              <span>
                <Link to="/terms-of-services">Terms of Use</Link>
              </span>{" "}
              and
              <span>
                {" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </Zoom>
  );
}

export default EmailAddress;
