    import React from 'react';
    import { useParams } from 'react-router-dom';
    import styles from './Stories.module.css';

    function SingleMessage() {
        const { id } = useParams();
        const storedMessage = JSON.parse(localStorage.getItem(`message-${id}`));
        const message = storedMessage ? storedMessage : { formattedHeadline: id };
      
    return (
        <div className={styles.singleMessageContainer}>
        <button className={styles.backButton} onClick={() => window.history.back()}>Back</button>
        <div className={styles.messageContent}>
            <h2>Submitted Details</h2>
            <ul className={styles.submittedDetails}>
            <li><strong>Full Name:</strong> {message.fullName}</li>
            <li><strong>Email:</strong> {message.email}</li>
            <li><strong>Type:</strong> {message.type}</li>
            <li><strong>Headline:</strong> {message.headline}</li>
            <li><strong>Description:</strong> {message.description}</li>
            </ul>
        </div>
        </div>
    );
    }

    export default SingleMessage;
