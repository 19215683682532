import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import img1 from "../../../../assets/slider1.png";
import img2 from "../../../../assets/slid2.png";
import img3 from "../../../../assets/slid3.png";
import img4 from "../../../../assets/slid4.png";
import img5 from "../../../../assets/slid5.png";
import img6 from "../../../../assets/slid6.png";
import img7 from "../../../../assets/slid7.png";
import img8 from "../../../../assets/slid8.png";

// import required modules
import { Pagination } from "swiper";
const HomepageSlider =() => {
    return (
        <div className="homepage-slider">
                 <Swiper
       
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        // modules={[Pagination]}
        className="mySwiper"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          600: {
            slidesPerView: 3,
            spaceBetween: -10,
          },
          800: {
            slidesPerView: 4,
            spaceBetween: -10,
          },

          1200: {
            slidesPerView: 4,
          },
        }}
      >
        <SwiperSlide><img src={img1} alt="slider"/></SwiperSlide>
        <SwiperSlide><img src={img2} alt="slider"/></SwiperSlide>
        <SwiperSlide><img src={img3} alt="slider"/></SwiperSlide>
        <SwiperSlide><img src={img4} alt="slider"/></SwiperSlide>
        <SwiperSlide><img src={img5} alt="slider"/></SwiperSlide>
        <SwiperSlide><img src={img6} alt="slider"/></SwiperSlide>
        <SwiperSlide><img src={img7} alt="slider"/></SwiperSlide>
        <SwiperSlide><img src={img8} alt="slider"/></SwiperSlide>
       
      </Swiper>
        </div>
    )
}

export default HomepageSlider;