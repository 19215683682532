import React, { useState } from "react";

import Zoom from "react-reveal/Zoom"; 
import LazyLoad from 'react-lazyload';


function Connections({ formData, setFormData, page, setPage, step2 }) {
  const [selected, setSelected] = useState({
    cards: [
      {
        id: 0,
        Explain: step2.acf.step_2[0].connection_text,
        isChecked: false,
        img: step2.acf.step_2[0].connection_image,
      },
      {
        id: 1,
        Explain: step2.acf.step_2[1].connection_text,
        isChecked: false,
        img: step2.acf.step_2[1].connection_image,
      },
      {
        id: 2,
        Explain: step2.acf.step_2[2].connection_text,
        isChecked: false,
        img: step2.acf.step_2[2].connection_image,
      },
      {
        id: 3,
        Explain: step2.acf.step_2[3].connection_text,
        isChecked: false,
        img: step2.acf.step_2[3].connection_image,
      },
      {
        id: 4,
        Explain: "none",
        isChecked: false,
        cssId: "none",
      },
    ],
  });
  const checked = (id) => {
    const newCards = selected.cards.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isChecked: true,
        };
      } else {
        return {
          ...item,
          isChecked: false,
        };
      }
    });
    setSelected({
      cards: newCards,
    });

    setFormData({
      ...formData,
      connections: newCards.filter((item) => item.isChecked)[0],
    });
  };
  return (
    <div className="connections">
      {selected.cards.map((event) => (
        <Zoom delay={100} duration={200} key={event}>
          <div
            className="cardConnection"
            id={event.cssId}
            onClick={() => {
              checked(event.id);
              setPage((currPage) => currPage + 1);
            }}
          >
            <div className="cardImgConnection">
            <LazyLoad height={200}>
              <img src={event.img} alt="connection image" />
              </LazyLoad>
            </div>
            <p className="connection-desc">{event.Explain}</p>

            <input
              name="connection"
              value={formData.connections.Explain}
              style={{ display: "none" }}
            />
          </div>
        </Zoom>
      ))}
    </div>
  );
}

export default Connections;
